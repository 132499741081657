.btn {
    position: relative;
    display: inline-block;
    font-family: $font--primary;
    font-size: 1em;
    font-weight: bold;
    line-height: 1em;
    background: none;
    border: none;
    border-radius: 4px;
    padding: 0 1em;
    color: $color--text;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    outline: none;
    white-space: nowrap;

    &:hover {
        text-decoration: none;
    }

    &--default {
        height: $height--button;
        line-height: $height--button - 1;
        background: $color--primary;
        color: $color--white;
        padding: 0 2em;
        transition: background .3s ease-in-out;

        &:hover {
            background: darken($color--primary, 7%);
        }

        &:focus {
            top: 1px;
            background: darken($color--primary, 10%);
            box-shadow: inset -1px -3px 3px rgba($color--black, .2);
        }
    }
}
