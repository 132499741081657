.auth {
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -200px;
    margin-left: -200px;
    padding: 2em 2em 1em 2em;

    &__title {
        font-size: 1.4em;
        font-weight: bold;
        margin-bottom: 1em;
    }

    &__form {
        &__field {
            display: block;
            margin-top: .75em;

            label {
                font-size: .9em;
                font-weight: bold;
                display: block;
                line-height: 1.5;
            }

            input {
                width: 100%;
                height: $height--input;
                line-height: $height--input;
                font: normal .9em $font--primary;
                background: lighten($color--gray, 5%);
                border: 1px solid $color--gray-dark;
                border-radius: $radius--default;
                padding: 0 1em;
                outline: none;

                &:focus {
                    border-color: darken($color--gray-dark, 20%);
                }
            }

            &.has-danger {
                label {
                    color: $color--danger;
                }

                input {
                    color: $color--danger;
                    border-color: $color--danger;
                }
            }
        }

        &__submit {
            margin: 1em 0;
        }
    }
}