@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&subset=latin-ext');

// Dimensions
$height--header: 70px;
$height--button: 50px;
$height--input: 42px;

// Defaults colors
$color--black: #000;
$color--white: #fff;
$color--gray: #edf4f8;
$color--gray-dark: #c3d7e2;
$color--text: #666;

// Brand colors
$color--primary: #0ad1ae;
$color--secondary: #4c546b;

// Status colors
$color--success: #34bc66;
$color--warning: #df662c;
$color--danger: #c11d1d;

// Fonts
$font--primary: 'Lato', Helvetica, Arial, sans-serif;
$font--default-size: 17px;
$font--default-color: $color--text;

// Box properties
$radius--default: 4px;

// Media query breakpoints
$mq--desktop: 'screen and (max-width: 1460px)';
$mq--tablet-landscape: 'screen and (max-width: 1040px)';
$mq--tablet-portrait: 'screen and (max-width: 860px)';
$mq--mobile-landscape: 'screen and (max-width: 640px)';
$mq--mobile-portrait: 'screen and (max-width: 420px)';
