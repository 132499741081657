.site-content {
    flex: 1;

    &__embed {
        padding: 2em;

        .box {
            margin-top: 21px;
        }

        .tableauPlaceholder {
            top: -21px;
        }
    }
}
