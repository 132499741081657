.sidebar {
    width: 250px;
    background: $color--secondary;
    color: $color--white;

    &__branding {
        height: $height--header;
        line-height: $height--header;
        background: lighten($color--secondary, 7%);
        box-shadow: 0 2px 5px rgba($color--black, .25);
        padding: 0 1em;
        font-size: 1.1em;
        font-weight: bold;

        a {
            color: $color--white;
            text-transform: uppercase;
            letter-spacing: -.05em;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__menu {
        padding: 2em 0;

        ul {
            list-style: none;

            li {
                a {
                    color: $color--white;
                    display: block;
                    padding: 1em;

                    &:hover {
                        text-decoration: none;
                        background: darken($color--secondary, 3%);
                    }
                }

                &.is-selected {
                    a {
                        color: $color--primary;
                        background: darken($color--secondary, 5%);
                    }
                }
            }
        }
    }
}