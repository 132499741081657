.grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__item {
        width: 22%;
        margin: 1em;

        img {
            width: 100%;
            height: auto;
            border: 1px solid $color--gray-dark;
            border-radius: $radius--default;
        }

        h2 {
            font-size: 1.1em;
            font-weight: bold;
            line-height: 1.5;
        }
    }
}

@media #{$mq--desktop} {
    .grid {
        flex-wrap: wrap;

        &__item {
            width: 28.5%;
        }
    }
}

@media #{$mq--tablet-landscape} {
    .grid {
        &__item {
            width: 44%;
        }
    }
}

@media #{$mq--tablet-portrait} {
    .grid {
        &__item {
            width: 100%;
        }
    }
}
