.error {
    display: block;
    padding: 7em 0;
    text-align: center;

    &__title {
        font-size: 1.9em;
        font-weight: bold;
        line-height: 1.5;
    }

    &__body {
        font-size: 1.2em;
        font-weight: 300;
    }
}