.alert {
    display: block;
    font-size: .9em;
    line-height: 1.5;
    font-weight: bold;
    color: $color--text;
    margin: 0 0 1.5em;

    &.alert--success {
        color: $color--success;
    }

    &.alert--danger {
        color: $color--danger;
    }

    &.alert--warning {
        color: $color--warning;
    }

    &.alert--success-border {
        border: 3px solid $color--success;
    }

    &.alert--danger-border {
        border: 3px solid $color--danger;
    }

    &.alert--warning-border {
        border: 3px solid $color--warning;
    }

    ul {
        margin-left: 1.5em;
    }
}
