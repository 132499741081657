.site-content {
    &__header {
        display: flex;
        justify-content: space-between;
        background: $color--white;
        height: $height--header;
        line-height: $height--header;
        padding: 0 2em;
        box-shadow: 0 2px 3px $color--gray-dark;

        .user-avatar {
            position: relative;
            font-size: .9em;

            img {
                width: 42px;
                height: 42px;
                position: absolute;
                top: 50%;
                left: -55px;
                margin-top: -21px;
                border-radius: 50%;
            }

            .logout {
                display: inline-block;
                font-weight: bold;
                margin-left: 1em;
            }
        }
    }
}

@media #{$mq--tablet-portrait} {
    .site-content {
        &__header {
            .welcome {
                display: none;
            }

            .user-avatar {
                display: flex;
                justify-content: space-between;
                padding-left: 42px;

                img {
                    left: -1em;
                }
            }
        }
    }
}
