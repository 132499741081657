html {
    height: 100%;
}

body {
    min-height: 100%;
    background: $color--gray;
    font: normal $font--default-size $font--primary;
    color: $color--text;
    text-align: center;
}

.site-container {
    min-height: 100vh;
    display: block;
    position: relative;
    text-align: left;
}

@media #{$mq--tablet-portrait} {
    .site-container {
        width: 700px;
        margin: 0 auto;
    }
}
